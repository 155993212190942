<template>
  <div class="use">
    <div class="head-public animate__animated animate__fadeIn">
      <img src="@/assets/img/banner2.png" alt="" />
    </div>
    <div class="cnt-public">
      <div class="title-public">
        <p>Application case</p>
        <h3>
          <span class="line-l"></span>
          <span class="center">应用案例</span>
          <span class="line-r"></span>
        </h3>
      </div>
      <div class="wp">
        <div
          :class="(index - 2) % 3 == 0 ? 'end' : ''"
          v-for="(item, index) in list"
          :key="index"
          @click="skip(item)"
        >
          <img :src="item.cover" alt="" />
          <div class="text">
            <h3>{{ item.title }}</h3>
            <p class="content-wp" v-html="item.content"></p>
          </div>
        </div>
      </div>
      <div class="block">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page.sync="page"
          :page-size="pageSize"
          @current-change="pageChange"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      pageSize: 9,
    };
  },
  methods: {
    gain() {
      this.axios({
        method: "get",
        url: "/wzn/application-case/page",
        params: {
          pageNo: this.page,
          pageSize: this.pageSize,
          // title: "home",
        },
      }).then((res) => {
        console.log("应用案例", res.data);
        this.list = res.data.data.list;
        // this.list = res.data.data.list.reverse();
        this.total = res.data.data.total;
      });
    },
    pageChange() {
      this.gain();
    },
    skip(item) {
      this.$router.push({
        path: "/Use/info",
        query: { title: item.title, cnt: item.content, imgUrl: item.cover },
      });
    },
  },
  created() {
    this.gain();
  },
};
</script>
    
<style scoped>
/* .use {
  min-height: 100vh;
} */
.wp {
  padding: 0 10%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}
.wp > div {
  width: 30%;
  margin-bottom: 30px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-right: 5%;
}
.wp > .end {
  margin-right: 0;
}
.wp > div > img {
  width: 100%;
  height: 300px;
  transition: all 0.3s;
}

.wp > div:hover img {
  /* box-shadow: 0 13px 52px rgba(172, 190, 215, 0.28); */
  transform: scale(1.1);
}
.wp > div::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.wp > div::after {
  width: 100%;
  height: 70%;
  position: absolute;
  left: 0%;
  bottom: 0%;
  content: "";
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  opacity: 0;
  transition: all 0.5s;
}
.wp > div:hover::before {
  animation: gradient-rectangle 1s 1;
  animation-delay: 0s;
  animation-timing-function: cubic-bezier(0, 0, 0.18, 0.96);
}
.wp > div:hover::after {
  opacity: 1;
}
@keyframes gradient-rectangle {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6)
    );
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    transform-origin: top;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6)
    );
    opacity: 0;
  }
}
.text {
  padding: 24px;
  background: #fff;
}
.text > h3 {
  font-size: 18px;
}
.content-wp {
  height: 25.2px;
  overflow: hidden;
}
.block {
  text-align: center;
}
</style>
<style>
.content-wp > p {
  margin-top: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #888;
  font-size: 14px;
}
.use .el-pagination button,
.use .el-pagination button span {
  min-width: 64px !important;
  height: 40px !important;
  line-height: 40px !important;
  background: #edeff4;
}
.use .el-pagination.is-background .el-pager li {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #edeff4;
}
</style>